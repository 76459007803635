<template>
  <v-scroll-y-transition>
    <v-footer v-if="footer" inset dark color="#004691" class="text-right">
      
    </v-footer>
  </v-scroll-y-transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState('ui', ['footer']),
  },
}
</script>
